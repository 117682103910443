import React, { useEffect, useState } from 'react'
import { Button } from '../../../../Components/Buttons'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { ConfigStyles, StyleHubspot } from './styles'
import ConfigureMapping from '../Components/Configure/ConfigureMapping';
import ReviewMapping from '../Components/Configure/ReviewMapping';
import { AlertForm, ICON } from '../../../../Utils/Alert';
import { useNavigate, useParams } from 'react-router-dom';
import { capitalLetter } from '../../../../Utils/String';
import { useGetAccountAppMutation, useGetGeneralConfigAppQuery, useGetObjectsMutation, useUpdateMappingMutation, useUpdateObjectsMutation } from '../../../../Store/api/apps';
import { getObjectsQueryUrl } from '../../../../Utils/QueryUrl';

const ConfigMapping = () => {
  let objQuery = getObjectsQueryUrl(window.location.href);
  const [tabIndex, setTabIndex] = useState(0);
  const [dataObjectCurrency, setDataObjectCurrency] = useState({});
  const [mappingData, setMappingData] = useState([]);
  const [directionData, setDirectionData] = useState("");

  let [searchAccount, { isLoading: isLoadingAccount, isSuccess: isSuccessAccount, data: responseAccount }] = useGetAccountAppMutation();
  let [searchObjectFetch, { isLoading: isLoadingGetObjects, isSuccess: isSuccessObjects, data: responseObjects }] = useGetObjectsMutation();
  let [updateObjectFetch, { isLoading: isLoadingUpdObjects, isSuccess: isSuccessUpdObjects }] = useUpdateObjectsMutation();
  let [updateMappingFetch, { isLoading: isLoadingUpdateObjects, isSuccess: isSuccessUpdate }] = useUpdateMappingMutation();
  let { isLoading: isLoadingGeneral, isSuccess: isSuccessGeneral, data: responseGeneral } = useGetGeneralConfigAppQuery(objQuery.appId);
  let navigate = useNavigate();
  let { object } = useParams();

  useEffect(() => {
    //consulta para identificar el ID del Cliente del integrador que esta en otra base de datos
    if(isSuccessGeneral){
      searchAccount({
        accountId: objQuery.accountId,
        portalId: objQuery.portalId,
        appId: objQuery.appId,
        nameApp: responseGeneral.data.config.app_name
      })
      searchObjectFetch({
        accountId: objQuery.accountId,
        appId: objQuery.appId,
        portalId: objQuery.portalId
      })
    }
  }, [isSuccessGeneral])

  useEffect(() => {
    if(isSuccessAccount && isSuccessGeneral && isSuccessObjects) {
      setDataObjectCurrency(responseObjects.data.objects.find(el => el.id === object))
    }
  }, [isSuccessAccount, isSuccessGeneral, isSuccessObjects])

  useEffect(() => {
    if(isSuccessUpdate) {
      // ******
      let objQuery = getObjectsQueryUrl(window.location.href);
      let updateObjects = JSON.parse(JSON.stringify(responseObjects.data.objects));
      let findCurrentObject = updateObjects.find(el => el.id === object);
      let now = new Date();
      now = now.toJSON();
      findCurrentObject.lastEdited.name = objQuery.userEmail;
      findCurrentObject.lastEdited.updatedAt = now;
      updateObjectFetch({
        accountId: objQuery.accountId,
        appId: objQuery.appId,
        portalId: objQuery.portalId,
        objects: updateObjects
      })
      AlertForm({title: "Changes saved correctly", icon: ICON.SUCCESS});
    }
  }, [isSuccessUpdate])
  
  const handleBtnConfig = () => {
    if(tabIndex === 1) {

      let cloneMapping = JSON.parse(JSON.stringify(mappingData));
      for (const item of cloneMapping) {
          delete item.temp;
      }
      updateMappingFetch({
        accountId: objQuery.accountId,
        portalId: objQuery.portalId,
        appId: objQuery.appId,
        direction: directionData,
        object: object,
        mappingProperties: cloneMapping
      })
     
    } else {
      setTabIndex(tabIndex + 1);
    }
  }
  return (
    <ConfigStyles>
      <div className='navbar-dark'>
        <div className='navbar-inner'>
          
          <div className='navbar-left'>
            <Button className={"hubspot btn-secondary"} onPress={() => navigate(`/apps/mapping${window.location.search}`)}>Exit </Button>
          </div>
          <div className='navbar-center'>
              <div className="text-center">
                <h4>{capitalLetter(object)} sync</h4>
                <small className="private-microcopy">
                  <ul className="uiList private-list--inline private-list--unstyled private-list--inline-divided">
                    <li className="uiListItem private-list__item"><span>{responseGeneral?.data?.name}</span></li>
                    <li className="uiListItem private-list__item"><span>{responseAccount?.data?.data.AppsSetting?.company || responseAccount?.data?.data.VtexSetting?.company || responseAccount?.data?.data.ShopifySetting?.company}</span></li>
                  </ul>
                </small>

              </div>
          </div>
          <div className='navbar-right'>
              <h2>Sync is {dataObjectCurrency.active ? "ON": "OFF"}</h2>
              <Button className={"hubspot btn-active"} onPress={handleBtnConfig}>{tabIndex === 1 ? "Save": "Next"} </Button>
          </div>
        </div>
      </div>
      <StyleHubspot>
        <section className='section-general'>
          <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            <TabList>
              <Tab className={"tab-item"}>1. Configure</Tab>
              <Tab className={"tab-item"}>2. Review</Tab>
            </TabList>
            <TabPanel>
               <ConfigureMapping 
                  account={responseAccount?.data} 
                  generalApp={responseGeneral?.data} 
                  typeObject={object} 
                  setMappingData={setMappingData} 
                  mappingData={mappingData}
                  setDirectionData={setDirectionData}
                  directionData={directionData}
                />
            </TabPanel>
            <TabPanel>
              <ReviewMapping 
                setMappingData={setMappingData} 
                mappingData={mappingData} 
                directionData={directionData}
                generalApp={responseGeneral?.data}
                typeObject={object} 
              />
            </TabPanel>
          </Tabs>
        </section>
      </StyleHubspot>
    </ConfigStyles>
  )
}

export default ConfigMapping