export const capitalLetter = (string) => {
  if(!string) return ""
  let firstLetter = string.charAt(0).toUpperCase();
  return firstLetter + string.slice(1);
};
export const capitalLetterFormatting = (string) => {
  if(!string) return ""
  let replaceCharacter = string.replaceAll("_", " ");
  let firstLetter = replaceCharacter.charAt(0).toUpperCase();
  return firstLetter + replaceCharacter.slice(1);
};
