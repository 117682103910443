import React, { useEffect, useState } from 'react';
import { StyleHubspot } from './styles';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { ObjectView } from '../Components/ObjectView';
import { RecordView } from '../Components/RecordView';
import { 
  useGetAccountAppMutation, 
  useGetGeneralConfigAppQuery, 
  useValidationTokenQuery
 } from '../../../../Store/api/apps';
import { useNavigate } from 'react-router-dom';
import { getObjectsQueryUrl } from '../../../../Utils/QueryUrl';

export const Mapping = () => {
  let objQuery = getObjectsQueryUrl(window.location.href);
  const [showFilter, setShowFilter] = useState(false)

  let { data: dataValidation, error: errorValidation, isLoading } = useValidationTokenQuery();
  let [searchAccount, { isLoading: isLoadingAccount, isSuccess: isSuccessAccount, data: responseAccount }] = useGetAccountAppMutation();
  let { isLoading: isLoadingGeneral, isSuccess: isSuccessGeneral, data: responseGeneral } = useGetGeneralConfigAppQuery(objQuery.appId);
  let navigate = useNavigate();

  
  useEffect(() => {
    //consulta para identificar el ID del Cliente del integrador que esta en otra base de datos
    if(isSuccessGeneral){
      searchAccount({
        accountId: objQuery.accountId,
        portalId: objQuery.portalId,
        appId: objQuery.appId,
        nameApp: responseGeneral.data.config.app_name
      })
    }
  }, [isSuccessGeneral])

  useEffect(() => {
    if(errorValidation) {
      if(errorValidation.status === 403) {
        navigate('/');
      }
    }
  }, [errorValidation])
  
  return (
    <StyleHubspot>
      <section className='section-general'>
        <Tabs>
          <TabList>
            <Tab className={"tab-item"}>Object View</Tab>
            <Tab className={"tab-item"}>Record View</Tab>
          </TabList>
          <TabPanel>
            <ObjectView account={responseAccount?.data} generalApp={responseGeneral?.data}/>
          </TabPanel>
          <TabPanel>
            <RecordView account={responseAccount?.data} generalApp={responseGeneral?.data}/>
          </TabPanel>
        </Tabs>
      </section>
    </StyleHubspot>
  );
};
