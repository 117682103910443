import styled, { keyframes } from 'styled-components';

export const StyleHubspot = styled.section`
  font-family: "Lexend Deca", Helvetica, Arial, sans-serif;

  .section-general {
    padding: 1rem;
    position: relative;
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(223, 227, 235);
  }
  & .tab-item {

    display: inline-block;
    border: 1px solid transparent;
    border:1px solid #cbd6e2;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 12px 28px;
    font-size: 14px;
    font-weight: 400;
    color: rgb(51, 71, 91);
    transition: background-color 200ms cubic-bezier(0.25, 0.1, 0.25, 1);
    background-color: rgb(245, 248, 250);
    border-left: 1px solid rgb(203, 214, 226);
    cursor: pointer;
    &:first-child {
      /* border-left: 0px; */
      border-top-left-radius: inherit;
    }
    &.react-tabs__tab--selected {
        background: #fff;
        border-color: #aaa;
        border-bottom: none;
        background-color: rgb(255, 255, 255);
        color: black;
        border-radius: 5px 5px 0 0;
        font-weight: 500;
        &:focus-visible {
          outline: none;
        }
      }
    
  }
  & .react-tabs__tab-list {
      border-bottom:none;
    }
  & .h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: normal;
  }

  & .section-btn {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
`;

export const ConfigStyles = styled.section`
  width: 100%
  padding-left: 0;
  padding-right: 0;
  z-index: 2;
  & ::-webkit-scrollbar {
    width: 6px;
  }
  & ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 20px 20px var(--bg-dark-home);
    border-radius: 10px;
  }
  & ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 13px 6px var(--primary-hubspot-color);
  }
  & .navbar-dark {
    display: flex;
    background-color: #253342;
    color: #fff;
    padding: 0 20px;
    & .navbar-inner {
      min-height: 56px;
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      & > * {
        padding: 8px 0;
        -webkit-box-align: center;
        align-items: center;
        display: flex;
        flex-basis: 0%;
        -webkit-box-flex: 1;
        flex-grow: 1;
        max-width: 100%;
        white-space: nowrap;
      }
      & .navbar-center {
        justify-content: center;
        & .text-center {
          display: flex;
          align-items: center;
          flex-direction: column;
          & h4 {
            margin-bottom: 4px;
            margin-top: 0;
            font-size: 20px;
            font-weight: 600;
          }
          & small {
            font-size: 12px;
            line-height: 18px;
            & ul, & ul > li {
              display: inline-block;
              max-width: 100%;
            }
            & ul {
              list-style: none;
              margin-bottom: 0;
              margin-top: 0;
              padding-left: 0;
              & > li {
                padding-right: 7px;
               
              }
              & >li+li {
                  background-image: linear-gradient(180deg, #cbd6e2, #cbd6e2);
                  background-position: 0 50%;
                  background-repeat: no-repeat;
                  background-size: 1px 60%;
                  padding-left: 8px;
              }
            }
          }
        }
      }
      & .navbar-right {
        justify-content: flex-end;
        gap: 1rem;
        & h2 {
          font-size: 14px;
        }
      }
    }
  }
`;
