import React, { useState } from 'react'
import { MappingTable } from './MappingTable';
import { ConfigureStyles } from './styles';
import Bidirectional from '../../../../../Assets/img/Directions/bidirectional.svg';
import Left from '../../../../../Assets/img/Directions/left.svg';
import Right from '../../../../../Assets/img/Directions/right.svg';
import { capitalLetter } from '../../../../../Utils/String';

const ReviewMapping = ({setMappingData, mappingData, directionData, generalApp, typeObject}) => {

  return (
    <ConfigureStyles>
      <div className='sync-direction review'>
        <div className='title-main'>
          <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 48 48"><path fill="currentColor" fillRule="evenodd" d="M20 6h8v.734c.618 6.866 2.662 8.109 8.898 5.2l.69-.398l4 6.928l-.39.225c-5.904 4.1-5.965 6.509-.066 10.583l.457.264l-.02.034l-3.98 6.894l-.762-.44c-6.355-2.906-8.292-1.476-8.8 6L28 41.697V42h-8v-1.166c-.67-6.626-2.82-7.64-9.154-4.62l-.434.25l-4-6.928l1.035-.598c5.239-3.782 5.237-6.145-.103-9.935l-.932-.539l4-6.928l.223.129c6.47 3.097 8.7 2.166 9.365-4.463zm9 18a5 5 0 1 1-10 0a5 5 0 0 1 10 0m3 0a8 8 0 1 1-16 0a8 8 0 0 1 16 0m2 0c0 5.523-4.477 10-10 10s-10-4.477-10-10s4.477-10 10-10s10 4.477 10 10" clipRule="evenodd"/></svg> 
          <h2>Configure</h2>
        </div>
        <div className='section-sync'>
          <div className="grid">
              <div className='item-left'>
                <div>
                    <div className='image-app'>
                    <img src={generalApp.logo} alt="app" />
                    </div>
                    <div className='title-app'>
                    <span>{capitalLetter(generalApp.config?.app_name)} {capitalLetter(typeObject)}</span>
                    </div>
                </div>
              </div>
              <div className='item-center' >
                <img src={directionData === "bidirectional" ? Bidirectional : directionData === "hs_app" ? Left : Right} alt="edit" />
              </div>
              <div className='item-right'>
              <div>
                    <div className='image-app'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="1.6em" height="1.6em" viewBox="0 0 512 512"><path fill="#f97a5a" d="M267.4 211.6c-25.1 23.7-40.8 57.3-40.8 94.6c0 29.3 9.7 56.3 26 78L203.1 434c-4.4-1.6-9.1-2.5-14-2.5c-10.8 0-20.9 4.2-28.5 11.8s-11.8 17.8-11.8 28.6s4.2 20.9 11.8 28.5s17.8 11.6 28.5 11.6c10.8 0 20.9-3.9 28.6-11.6c7.6-7.6 11.8-17.8 11.8-28.5c0-4.2-.6-8.2-1.9-12.1l50-50.2c22 16.9 49.4 26.9 79.3 26.9c71.9 0 130-58.3 130-130.2c0-65.2-47.7-119.2-110.2-128.7V116c17.5-7.4 28.2-23.8 28.2-42.9c0-26.1-20.9-47.9-47-47.9S311.2 47 311.2 73.1c0 19.1 10.7 35.5 28.2 42.9v61.2c-15.2 2.1-29.6 6.7-42.7 13.6c-27.6-20.9-117.5-85.7-168.9-124.8c1.2-4.4 2-9 2-13.8C129.8 23.4 106.3 0 77.4 0C48.6 0 25.2 23.4 25.2 52.2c0 28.9 23.4 52.3 52.2 52.3c9.8 0 18.9-2.9 26.8-7.6zm89.5 163.6c-38.1 0-69-30.9-69-69s30.9-69 69-69s69 30.9 69 69s-30.9 69-69 69"/></svg>
                    </div>
                    <div className='title-app'>
                      <span>HubSpot {capitalLetter(typeObject)}</span>
                    </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div className="mapping">
        <h4>Field mappings</h4>
        <p>{mappingData.length} fields will be kept in sync</p>
        <MappingTable review={true} setMappingData={setMappingData} mappingData={mappingData}/>
      </div>
    </ConfigureStyles>
  )
}

export default ReviewMapping;