export const getObjectsQueryUrl = (url) => {
    // Crear un objeto URLSearchParams con los parámetros de la URL
    const queryParams = new URLSearchParams(url.split('?')[1]);

    // Convertir los parámetros en un objeto
    const paramsObj = {};
    queryParams.forEach((value, key) => {
        paramsObj[key] = value;
    });
    return paramsObj;
}