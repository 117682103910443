import React, { useState } from 'react'

import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
// A TanStack fork of Kent C. Dodds' match-sorter library that provides ranking information
import {
  rankItem,
} from '@tanstack/match-sorter-utils'

// needed for table body level scope DnD setup
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable'

// needed for row & cell level scope DnD setup
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { LinkStyles, StateStyle, TableStyles, PaginationStyles } from './styles'


export const TableHubSpot = ({dataTable, showFilter, columns, rowExpand, subComponent = null}) => {

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const [columnOrder, setColumnOrder] = useState(() => columns.map(c => c.id));

  // Define a custom fuzzy filter function that will apply ranking info to rows (using match-sorter utils)
  const fuzzyFilter = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
      itemRank,
    })
    // Return if the item should be filtered in/out
    return itemRank.passed
  }

  const table = useReactTable({
    data: dataTable,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter, //define as a filter function that can be used in column definitions
    },
    state: {
      columnFilters,
      globalFilter,
      columnOrder,
    },
    columnResizeMode: 'onChange', //change column resize mode to "onChange"

    defaultColumn: {
      size: 200, //starting column size
      minSize: 80, //enforced during column resizing
      maxSize: 300, //enforced during column resizing
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onColumnOrderChange: setColumnOrder,
    globalFilterFn: 'fuzzy', //apply fuzzy filter to the global filter (most common use case for fuzzy filter)
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: () => rowExpand,
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })
    // reorder columns after drag & drop
    function handleDragEnd(event) {
      const { active, over } = event
      if (active && over && active.id !== over.id) {
        setColumnOrder(columnOrder => {
          const oldIndex = columnOrder.indexOf(active.id)
          const newIndex = columnOrder.indexOf(over.id)
          return arrayMove(columnOrder, oldIndex, newIndex) //this is just a splice util
        })
      }
    }
  
    const sensors = useSensors(
      useSensor(MouseSensor, {}),
      useSensor(TouchSensor, {}),
      useSensor(KeyboardSensor, {})
    )

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <div className="p-2">
        <TableStyles className='overflow-x-auto'>
          <div className='section-table-header' {...{
              style: {
                width: table.getCenterTotalSize(),
              },
            }}>
            <div className='input-filter'>
              <DebouncedInput
                value={globalFilter ?? ''}
                onChange={value => setGlobalFilter(String(value))}
                className="p-2 font-lg shadow border border-block"
                placeholder="Search all columns..."
              />
              <div className='icon-search'>
                <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 1664 1664"><path fill="var(--primary-hubspot-color)" d="M1152 704q0-185-131.5-316.5T704 256T387.5 387.5T256 704t131.5 316.5T704 1152t316.5-131.5T1152 704m512 832q0 52-38 90t-90 38q-54 0-90-38l-343-342q-179 124-399 124q-143 0-273.5-55.5t-225-150t-150-225T0 704t55.5-273.5t150-225t225-150T704 0t273.5 55.5t225 150t150 225T1408 704q0 220-124 399l343 343q37 37 37 90"/></svg>
              </div>
            </div>
          </div>
          <table {...{
              style: {
                width: table.getCenterTotalSize(),
              },
            }}>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  <SortableContext
                    items={columnOrder}
                    strategy={horizontalListSortingStrategy}
                  >
                    {headerGroup.headers.map(header => {
                      return (
                        <>
                          <DraggableTableHeader key={header.id} header={header} table={table} showFilter={showFilter}/>
                        </>
                      )
                    })}
                  </SortableContext>
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map(row => {
                return (
                  <>
                    <tr key={row.id}>
                      {row.getVisibleCells().map(cell => {
                        return (
                          <SortableContext
                          key={cell.id}
                          items={columnOrder}
                          strategy={horizontalListSortingStrategy}
                        >
                          <DragAlongCell key={cell.id} cell={cell} />
                        </SortableContext>
                        )
                      })}
                    </tr>
                    {row.getIsExpanded() && (
                      <tr>
                        {/* 2nd row is a custom 1 cell row */}
                        <td colSpan={row.getVisibleCells().length}>
                          {renderSubComponent(subComponent, { row })}
                        </td>
                      </tr>
                    )}
                  </>
                )
              })}
            </tbody>
          </table>
        </TableStyles>
        {(table.getCanNextPage() || table.getCanPreviousPage()) && <PaginationStyles>
          <div className="flex items-center gap-2">
            <button
              className={`btn ${table.getCanPreviousPage() ? "active": "inactive"}`}
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" viewBox="0 0 24 24"><path fill="currentColor" d="m8.121 12l4.94-4.939l-2.122-2.122L3.879 12l7.06 7.061l2.122-2.122z"/><path fill="currentColor" d="M17.939 4.939L10.879 12l7.06 7.061l2.122-2.122L15.121 12l4.94-4.939z"/></svg>
            </button>
            <button
              className={`btn ${table.getCanPreviousPage() ? "active": "inactive"}`}
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" viewBox="0 0 24 24"><path fill="currentColor" d="M13.939 4.939L6.879 12l7.06 7.061l2.122-2.122L11.121 12l4.94-4.939z"/></svg>
            </button>
            <button
              className={`btn ${table.getCanNextPage() ? "active": "inactive"}`}
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
             <svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" viewBox="0 0 24 24"><path fill="currentColor" d="M10.061 19.061L17.121 12l-7.06-7.061l-2.122 2.122L12.879 12l-4.94 4.939z"/></svg>
            </button>
            <button
              className={`btn ${table.getCanNextPage() ? "active": "inactive"}`}
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" viewBox="0 0 24 24"><path fill="currentColor" d="m13.061 4.939l-2.122 2.122L15.879 12l-4.94 4.939l2.122 2.122L20.121 12z"/><path fill="currentColor" d="M6.061 19.061L13.121 12l-7.06-7.061l-2.122 2.122L8.879 12l-4.94 4.939z"/></svg>
            </button>
            
          </div>  
          <div className='info-table'>
            <span className="page-info">
                <div>Page</div>
                <strong style={{color: "var(--primary-hubspot-color)"}}>
                  {table.getState().pagination.pageIndex + 1} of{' '}
                  {table.getPageCount()}
                </strong>
              </span>
              <span className='go-page'>
                <span>Go to page:</span>
                <div className="input-filter">
                  <input
                    type="number"
                    min="1"
                    max={table.getPageCount()}
                    defaultValue={table.getState().pagination.pageIndex + 1}
                    onChange={e => {
                      const page = e.target.value ? Number(e.target.value) - 1 : 0
                      table.setPageIndex(page)
                    }}
                    className="border p-1 rounded w-16"
                  />
                </div>
                
            </span>
            <div className="input-filter">
              <select
                value={table.getState().pagination.pageSize}
                onChange={e => {
                  table.setPageSize(Number(e.target.value))
                }}
              >
                {[10, 25, 50, 100].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize} per page
                  </option>
                ))}
              </select>
            </div>
          
          </div>
        </PaginationStyles>}
      </div>
    </DndContext>
  )
}


const DraggableTableHeader = ({
  header,
  table,
  showFilter
}) => {
  const { attributes, isDragging, listeners, setNodeRef, transform } =
    useSortable({
      id: header.column.id,
    })

  const style = {
    opacity: isDragging ? 0.8 : 1,
    position: 'relative',
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: 'width transform 0.2s ease-in-out',
    whiteSpace: 'nowrap',
    width: header.column.getSize(),
    zIndex: isDragging ? 1 : 0,
  }

  return (
    <th colSpan={header.colSpan} ref={setNodeRef} style={style} className={header.id === table.getState()?.sorting[0]?.id ? "active": ""}>
     
     {header.isPlaceholder ? null : (
        <>
          <div
            {...{
              className: header.column.getCanSort()
                ? 'cursor-pointer select-none'
                : '',
              onClick: header.column.getToggleSortingHandler(),
            }}
            className="header-table"
          >
            <div>
              <button {...attributes} {...listeners} className="btn-drag-order">
                <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24"><path fill="currentColor" d="M9 3h2v2H9zm4 0h2v2h-2zM9 7h2v2H9zm4 0h2v2h-2zm-4 4h2v2H9zm4 0h2v2h-2zm-4 4h2v2H9zm4 0h2v2h-2zm-4 4h2v2H9zm4 0h2v2h-2z"/></svg>
              </button>
              {flexRender(
                header.column.columnDef.header,
                header.getContext()
              )}
              
            </div>
            <div>
              {{
                asc: <svg width={"8px"} role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.5 14"><polygon fill="rgb(203, 214, 226)" points="7.5 5 0 5 3.75 0 7.5 5" className="TableSortArrows__Arrow-j4mnp3-0 kQVNun"></polygon><polygon fill="rgb(0, 164, 189)" points="0 9 7.5 9 3.75 14 0 9" className="TableSortArrows__Arrow-j4mnp3-0 enIPyn"></polygon></svg>,
                desc: <svg width={"8px"} role="presentation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.5 14"><polygon fill="rgb(0, 164, 189)" points="7.5 5 0 5 3.75 0 7.5 5" className="TableSortArrows__Arrow-j4mnp3-0 enIPyn"></polygon><polygon fill="rgb(203, 214, 226)" points="0 9 7.5 9 3.75 14 0 9" className="TableSortArrows__Arrow-j4mnp3-0 kQVNun"></polygon></svg>,
              }[header.column.getIsSorted()] ?? null}

              <div
                {...{
                  onDoubleClick: () => header.column.resetSize(),
                  onClick: (e) => e.stopPropagation(),
                  onMouseDown: header.getResizeHandler(),
                  onTouchStart: header.getResizeHandler(),
                  className: `resizer ${
                    table.options.columnResizeDirection
                  } ${
                    header.column.getIsResizing() ? 'isResizing' : ''
                  }`,
                }}
              />
            </div>
            
          </div>
          {showFilter && header.column.getCanFilter() ? (
            <div className='input-filter'>
              <Filter column={header.column} />
            </div>
          ) : null}
        </>
      )}

    </th>
  )
}

const DragAlongCell = ({ cell }) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  })
  const style = {
    opacity: isDragging ? 0.8 : 1,
    position: 'relative',
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: 'width transform 0.2s ease-in-out',
    width: cell.column.getSize(),
    zIndex: isDragging ? 1 : 0,
  }

  return (
    <td style={style} ref={setNodeRef}>
        {flexRender(
          cell.column.columnDef.cell,
          cell.getContext()
        )}
    </td>
  )
}

const renderSubComponent = (SubComponent, { row }) => {
  return SubComponent === null ? (
    <pre style={{ fontSize: '10px' }}>
      <code>{JSON.stringify(row.original, null, 2)}</code>
    </pre>
  ) : <SubComponent row={row} />
}
export const  Filter = ({ column }) => {
  const columnFilterValue = column.getFilterValue();
  const { filterVariant } = column.columnDef.meta ?? {};
  return filterVariant === 'hidden' ? (<></>):  
  filterVariant === 'range' ? (
    <div>
      <div className="flex space-x-2">
        {/* See faceted column filters example for min max values functionality */}
        <DebouncedInput
          type="number"
          value={(columnFilterValue)?.[0] ?? ''}
          onChange={value =>
            column.setFilterValue((old) => [value, old?.[1]])
          }
          placeholder={`Min`}
          className="w-24 border shadow rounded"
        />
        <DebouncedInput
          type="number"
          value={(columnFilterValue)?.[1] ?? ''}
          onChange={value =>
            column.setFilterValue((old) => [old?.[0], value])
          }
          placeholder={`Max`}
          className="w-24 border shadow rounded"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : filterVariant === 'select' ? (
    <select
      onChange={e => column.setFilterValue(e.target.value)}
      value={columnFilterValue?.toString()}
    >
      {/* See faceted column filters example for dynamic select options */}
      <option value="">All</option>
      {column.columnDef.options?.map(option => {
        return <option value={option?.id} key={option?.id}>{option?.label}</option>
      })}
    </select>
  ) : (
    <DebouncedInput
      className="w-36 border shadow rounded"
      onChange={value => column.setFilterValue(value)}
      placeholder={`Search...`}
      type="text"
      value={(columnFilterValue ?? '')}
    />
    // See faceted column filters example for datalist search suggestions
  )
}

// A typical debounced input react component
export const  DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}

